import React from 'react'
import SliderInicio from '../Slider/SliderInicio'
import ContainerReservarViaje from '../ContainerReservar_Viaje/ContainerReservarViaje'
import Boton_Pide_Tu_Servicio from '../Boton_Pide_Tu_Servicio/Boton_Pide_Tu_Servicio'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'

export default function VistaBotonPedirServicio() {
  return (
    <div>
      <Navbar />
      <SliderInicio />
      <ContainerReservarViaje />
      <Boton_Pide_Tu_Servicio />
      <Footer />
    </div>
  )
}
