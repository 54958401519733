import React, { useContext, useState, useEffect, useRef } from 'react'
import { Typography, Grid, Container, TextField, Button, TextareaAutosize, Box, InputAdornment, NativeSelect, Alert } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { main_inicio } from '../../components/FormReservarViaje/theme_form_reservar_viaje';
import EmailRounded from '@mui/icons-material/EmailRounded';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PhoneIcon from '@mui/icons-material/Phone';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import BackpackIcon from '@mui/icons-material/Backpack';
import WorkIcon from '@mui/icons-material/Work';
import LuggageIcon from '@mui/icons-material/Luggage';
import axios from 'axios';

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import "./FormularioReservarViaje.css";
import Select from 'react-select';

import moment from 'moment-timezone';

import { useNavigate } from 'react-router-dom';

import { ReservaViajeContext } from "../../contexts/ReservaViajeContext";

import ReCAPTCHA from 'react-google-recaptcha';

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
const API_LUXXOR_HOST = process.env.REACT_APP_API_LUXXOR_HOST;


export default function FormularioReservarViaje() {
    const { servicioDefault } = useContext(ReservaViajeContext);
    const [selectedServicio, setSelectedServicio] = useState(null);

    const select_options = [
        { value: "", label: "[seleccione servicio]" },
        { value: 'Traslados a Aeropuerto y Hoteles', label: 'Traslados a Aeropuerto y Hoteles' },
        { value: 'Traslado Ejecutivo Recurrente', label: 'Traslado Ejecutivo Recurrente' },
        { value: 'Desplazamientos Diarios', label: 'Desplazamientos Diarios' },
        { value: 'Turismo de Lujo', label: 'Turismo de Lujo' },
        { value: 'Viajes de Negocios', label: 'Viajes de Negocios' },
        { value: 'Eventos Empresariales, Deportivos y de Entretenimiento', label: 'Eventos Empresariales, Deportivos y de Entretenimiento' },
        { value: 'Bodas y Celebraciones en General', label: 'Bodas y Celebraciones en General' },
    ];

    useEffect(() => {

        if (servicioDefault === "") {
            setSelectedServicio(select_options[0]);
            setTipoServicio("");
        } else {
            const index = select_options.findIndex(option => option.value === servicioDefault);
            const servicio = select_options[index];
            setSelectedServicio(select_options[index]);
            setTipoServicio(servicio.value);
        }

    }, [servicioDefault]);


    const { selectedDate, selectedTime, numberOfPeople, location, location_destino, selectedFormatDate, setSelectedFormatDate } = useContext(ReservaViajeContext);

    const [showMessage, setShowMessage] = useState(false);
    const datosIngresados = selectedDate && selectedTime && numberOfPeople && location && location_destino;
    const navigate = useNavigate();
    const dateObject = new Date(selectedDate);


    const [nombre, setNombre] = useState('');
    const [correo_electronico, setCorreo] = useState('');
    const [telefono, setTelefono] = useState('');
    const [tipo_servicio, setTipoServicio] = useState('');
    const [equipaje_bolso, setBolso] = useState(0);
    const [equipaje_maleta, setMaleta] = useState(0);
    const [equipaje_mochila, setMochila] = useState(0);
    const [equipaje_otro, setOtro] = useState(0);
    const [detalle_viaje, setDetalleViaje] = useState('');

    const [mensajeAlertaError, setMensajeAlertaError] = useState(false);
    const [mensajeAlertaSuccess, setMensajeAlertaSuccess] = useState(false);

    const captcha = useRef(null);
    const [captchaIsDone, setCaptchaIsDone] = useState(true);

    const handleRecaptcha = () => {
        setCaptchaIsDone(true);
    };

    const handleChangeNombre = (e) => {
        setNombre(e.target.value);
        setMensajeAlertaError(false);
        setMensajeAlertaSuccess(false);
    };

    const handleChangeTelefono = (e) => {
        setTelefono(e.target.value);
        setMensajeAlertaError(false);
        setMensajeAlertaSuccess(false);
    };

    const handleChangeCorreo = (e) => {
        setCorreo(e.target.value);
        setMensajeAlertaError(false);
        setMensajeAlertaSuccess(false);
    };

    const handleChangeServicio = (e) => {

        const index = select_options.findIndex(option => option.value === e.value);
        const servicio = select_options[index];

        setSelectedServicio(select_options[index]);
        setTipoServicio(servicio.value);

        setMensajeAlertaError(false);
        setMensajeAlertaSuccess(false);
    };


    const handleChangeBolso = (e) => {
        setBolso(e.target.value);
        setMensajeAlertaError(false);
        setMensajeAlertaSuccess(false);
    };

    const handleChangeMaleta = (e) => {
        setMaleta(e.target.value);
        setMensajeAlertaError(false);
        setMensajeAlertaSuccess(false);
    };

    const handleChangeMochila = (e) => {
        setMochila(e.target.value);
        setMensajeAlertaError(false);
        setMensajeAlertaSuccess(false);
    };

    const handleChangeOtroEquipaje = (e) => {
        setOtro(e.target.value);
        setMensajeAlertaError(false);
        setMensajeAlertaSuccess(false);
    };

    const handleChangeDetalleViaje = (e) => {
        setDetalleViaje(e.target.value);
        setMensajeAlertaError(false);
        setMensajeAlertaSuccess(false);
    };


    const handleGuardarReserva = async () => {
        setMensajeAlertaError(false);
        setMensajeAlertaSuccess(false);
        try {
            if (!captchaIsDone) {
                return;
            }

            if (!selectedDate || !selectedTime || !numberOfPeople || !location || !location_destino || !nombre || !correo_electronico || !telefono || !tipo_servicio) {
                setMensajeAlertaError("Te pedimos que por favor completes todos los campos necesarios para tu reserva de viaje. Incluye detalles como la fecha, hora, cantidad de personas, así como la información personal, origen, destino y tipo de servicio. Tu colaboración con esta información asegurará una reserva exitosa y una experiencia de viaje sin contratiempos. ¡Gracias!");
                return;
            }

            const tokenReCaptcha = captcha.current.getValue();

            const datosReserva = {
                fecha: selectedDate,
                hora: selectedTime,
                cantidad_personas: numberOfPeople,
                ubicacion_origen: location,
                ubicacion_destino: location_destino,
                nombre: nombre,
                correo_electronico: correo_electronico,
                telefono: telefono,
                tipo_servicio: tipo_servicio,
                equipaje_bolso: equipaje_bolso,
                equipaje_maleta: equipaje_maleta,
                equipaje_mochila: equipaje_mochila,
                equipaje_otro: equipaje_otro,
                detalle_viaje: detalle_viaje,
                tokenReCaptcha: tokenReCaptcha
            };

            const response = await axios.post(`${API_LUXXOR_HOST}/luxxor/reservar`, datosReserva);

            if (!response.data.success) {
                setMensajeAlertaError(response.data.message);
                // toast.error(response.data.message, {
                //     position: "bottom-left",
                //     autoClose: 2000,
                // });
            } else {
                setMensajeAlertaSuccess(response.data.message);
            }

        } catch (error) {
            //console.error('Error al guardar la reserva:', error.message);
            toast.error(error, {
                position: "top-center",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    };

    useEffect(() => {
        let timeoutId;
        if (mensajeAlertaSuccess) {
            timeoutId = setTimeout(() => {
                navigate("/solicitudreserva");
            }, 2000);
        }
        return () => clearTimeout(timeoutId);
    }, [mensajeAlertaSuccess, navigate]);



    if (datosIngresados) {
        const parsedDate = moment(selectedDate, 'YYYY-MM-DD').toDate();

        if (!isNaN(parsedDate.getTime())) {
            const formatearFecha = moment(selectedDate).tz('America/Santiago').format('DD/MM/YYYY');
            setSelectedFormatDate(formatearFecha);
        } else {
            console.error('La fecha seleccionada no es válida.');
        }
    };

    return (
        <ThemeProvider theme={main_inicio}>
            <Container maxWidth={false} style={{ marginTop: '20px', textAlign: 'center' }}>

                <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ mb: "40px" }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h6" align="center" style={{ color: 'white' }}>
                            ¡Estás por comenzar un viaje inolvidable!
                        </Typography>
                    </Grid>
                </Grid>

                {datosIngresados && (
                    <Box
                        sx={{
                            padding: '10px',
                            textAlign: 'center',
                            color: 'white',
                            marginBottom: '20px',
                            marginTop: '-30px'
                        }}
                    >
                        <Typography
                            variant="a"
                            sx={{
                                color: "white",
                                fontSize: {
                                    xl: 18,
                                    lg: 18,
                                    md: 16,
                                    sm: 16,
                                    xs: 14,
                                }
                                , textAlign: 'justify'
                            }}
                        >
                            ¡Estás a punto de reservar un emocionante viaje para el <strong>{selectedFormatDate}</strong> a las <strong>{selectedTime} </strong>
                            para <strong>{numberOfPeople}</strong> persona(s)!
                            Pasaremos a buscarte en <strong>{location}</strong> y te llevaremos a <strong>{location_destino}</strong>.
                            Completa el formulario para asegurar tu reserva. ¡Esperamos acompañarte en este viaje especial!
                        </Typography>

                    </Box>
                )}
                {mensajeAlertaError && (
                    <>
                        <Alert variant="filled" severity="error" sx={{ mb: "20px" }}>
                            {mensajeAlertaError}
                        </Alert>
                    </>
                )}

                {mensajeAlertaSuccess && (
                    <>
                        <Alert variant="filled" severity="success" sx={{ mb: "20px" }}>
                            {mensajeAlertaSuccess}
                        </Alert>
                    </>
                )}
                <Grid container spacing={2} xs={12} sm={12} md={10} lg={8} xl={8} sx={{ m: "auto" }}>

                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="h6" sx={{ color: 'white', fontSize: '15px', textAlign: 'center' }}>
                            Nombre de Contacto
                        </Typography>
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            value={nombre}
                            onChange={handleChangeNombre}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircleIcon />
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                        />
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="h6" sx={{ color: 'white', fontSize: '15px', textAlign: 'center' }}>
                            Correo electr&oacute;nico
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            value={correo_electronico}
                            onChange={handleChangeCorreo}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailRounded />
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                        />
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="h6" sx={{ color: 'white', fontSize: '15px', textAlign: 'center' }}>
                            N&uacute;mero de tel&eacute;fono
                        </Typography>
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            type='tel'
                            value={telefono}
                            onChange={handleChangeTelefono}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PhoneIcon />
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="h6" sx={{ color: 'white', fontSize: '15px', textAlign: 'center' }}>
                            Tipo de Servicio
                        </Typography>
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                       <Select
                            id="id-servicio"
                            options={select_options}
                            onChange={handleChangeServicio}
                            value={selectedServicio}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#fbcc02',
                                    primary: '#d7a205',
                                },
                            })}
                        />
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="h6" sx={{ color: 'white', fontSize: '15px', textAlign: 'center' }}>
                            Equipaje
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Grid container spacing={0} xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" alignItems="center" >
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button fullWidth >Bolso</Button>

                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <TextField
                                    fullWidth
                                    value={equipaje_bolso}
                                    onChange={handleChangeBolso}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <ShoppingBagIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    type='number'
                                />

                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="h6" sx={{ color: 'white', fontSize: '15px', textAlign: 'center' }}>
                            &nbsp;
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Grid container spacing={0} xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" alignItems="center" >
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button fullWidth >Maleta</Button>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <TextField
                                    fullWidth
                                    value={equipaje_maleta}
                                    onChange={handleChangeMaleta}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <WorkIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    type='number'
                                />

                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="h6" sx={{ color: 'white', fontSize: '15px', textAlign: 'center' }}>
                            &nbsp;
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Grid container spacing={0} xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" alignItems="center" >
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button fullWidth >Mochila</Button>

                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <TextField
                                    fullWidth
                                    value={equipaje_mochila}
                                    onChange={handleChangeMochila}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <BackpackIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    type='number'
                                />

                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="h6" sx={{ color: 'white', fontSize: '15px', textAlign: 'center' }}>
                            &nbsp;
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Grid container spacing={0} xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" alignItems="center" >
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button fullWidth >Otro</Button>

                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <TextField
                                    fullWidth
                                    value={equipaje_otro}
                                    onChange={handleChangeOtroEquipaje}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LuggageIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    type='number'
                                />

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="h6" sx={{ color: 'white', fontSize: '15px', textAlign: 'center' }}>
                            Detalles del viaje
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <TextareaAutosize
                            value={detalle_viaje}
                            onChange={handleChangeDetalleViaje}
                            minRows={5}
                            style={{ width: '100%', backgroundColor: '#f1f1f1' }}
                        />
                    </Grid>
                    <Box style={{ display: 'flex', marginBottom: "20px", marginTop: "20px", width: '100%' }}>
                        <ReCAPTCHA
                            ref={captcha}
                            sitekey={RECAPTCHA_SITE_KEY}
                            theme='dark'
                            onChange={handleRecaptcha}
                            style={{ margin: 'auto' }}
                        />
                    </Box>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button variant="contained" onClick={() => handleGuardarReserva()} style={{ borderRadius: '20px', fontWeight: 'bold', padding: '6px 16px', marginBottom: '10px' }}>
                            Solicitar Reserva de Viaje
                        </Button>
                    </Grid>

                </Grid>

            </Container>
        </ThemeProvider >

    )
}
