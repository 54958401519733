import axios from 'axios';
import { marked } from 'marked';


const API_CHATBOT = process.env.REACT_APP_API_CHATBOT;

export function renderBoldTextTemp(text) {
    text = text.replace(/- /g, '• ');
    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    const boldRegex = /\*\*(.*?)\*\*/g;
    return text
        .replace(boldRegex, '<strong>$1</strong>')
        .replace(urlRegex, function (url) {
            let hyperlink = url;
            if (!hyperlink.match('^https?:\/\/')) {
                hyperlink = 'http://' + hyperlink;
            }
            return `<a href="${hyperlink}" target="_blank" rel="noopener noreferrer">${url}</a>`;
        });
}

export function renderBoldText(text) {
    // Reemplaza "- " por "• "
    //text = text.replace(/- /g, '• ');

    // Configura marked para permitir el formateo de HTML seguro
    marked.setOptions({
        breaks: true, // Convierte los saltos de línea en <br>
        gfm: true, // Usa GitHub Flavored Markdown (GFM)
    });

    // Convierte el texto Markdown a HTML usando marked
    return marked(text);
}

export function handleOpenConfirmDialog(setShowConfirmDialog) {
    setShowConfirmDialog(true);
}

export function handleConfirmClose(onClose, setShowConfirmDialog) {
    onClose();
    setShowConfirmDialog(false);
}

export function handleCancelClose(setShowConfirmDialog) {
    setShowConfirmDialog(false);
}

export async function handleOptionClick(option, stateSetters) {
    const { setLoading, setMessages, setLastMessageSender, messages, renderBoldText } = stateSetters;

    setLoading(true);
    const userMessage = {
        text: option,
        options: null,
        isUserMessage: true,
        timestamp: new Date().toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' }),
    };

    setMessages(prevMessages => [...prevMessages, userMessage]);
    setLastMessageSender(userMessage.isUserMessage);

    try {
        const response = await axios.post(`${API_CHATBOT}/api/chat`, {
            question: option,
        });

        const botMessage = {
            text: renderBoldText(response.data.answer),
            options: null,
            isUserMessage: false,
            timestamp: new Date().toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' }),
        };

        setMessages(prevMessages => [...prevMessages, botMessage]);
    } catch (error) {
        console.error('Error sending question to API:', error);
    } finally {
        setLoading(false);
    }
}

export function handleMoreQuestionsClick(messagesStartRef, setIsButtonVisible) {
    if (messagesStartRef.current) {
        messagesStartRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    setIsButtonVisible(false);
}