import './App.css';
import React, { useState, useEffect } from 'react';
import Bienvenida from './components/Bienvenida/Bienvenida';
import Inicio from './components/Inicio/Inicio';
import FormularioReservarViaje from './components/FormReservarViaje/FormularioReservarViaje';
import SolicitudReserva from './components/SolicitudReserva/SolicitudReserva';
import BotonLuxxor from './components/BotonLuxxor/BotonLuxxor';
import Boton_Pide_Tu_Servicio from './components/Boton_Pide_Tu_Servicio/Boton_Pide_Tu_Servicio';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ReservaViajeProvider } from './contexts/ReservaViajeContext';
import GaleriaFotos2 from './components/GaleriaFotos/GaleriaFotos2';
import ReservaTuViaje from './components/VistaBotonReservaTuViaje/ReservaTuViaje';
import MenuBotonLuxxor from './components/VistaBotonLuxxor/VistaBotonLuxxor';
import VistaGaleriaFotos from './components/VistaGaleriaFotos/GaleriaFotos';
import VistaBotonPedirServicio from './components/VistaBotonPedirServicio/VistaBotonPedirServicio';
import FormularioPartner from './components/FormularioPartner/FormularioPartner';
import VistaBotonPartner from './components/VistaBotonPartner/VistaBotonPartner';
import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';
import Cotizacion from './components/Cotizacion/Cotizacion';
import SolicitudCotizacion from './components/SolicitudCotizacion/SolicitudCotizacion';
import GoogleMapsTracking from './components/DemoTraking/GoogleMapsTracking'
import GoogleMapsTrackingDistance from './components/DemoTraking/GoogleMapsTrackingDistance'


import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VistaBotonCotizacion from './components/VistaBotonCotizacion/VistaBotonCotizacion';
import Vista_Traslado_Ejecutivo_Recurrente from './components/Vista_Traslado_Ejecutivo_Recurrente/Vista_Traslado_Ejecutivo_Recurrente';
import Vista_Bodas_Celebridades_En_General from './components/Vista_Bodas_Celebridades_En_General/Vista_Bodas_Celebridades_En_General';
import Vista_Eventos_Empresariales_Deportivos_Entretenimiento from './components/Vista_Eventos_Empresariales_Deportivos_Entretenimiento/Vista_Eventos_Empresariales_Deportivos_Entretenimiento';
import Vista_Traslados_Aeropuertos_y_Hoteles from './components/Vista_Traslados_Aeropuertos_y_Hoteles/Vista_Traslados_Aeropuertos_y_Hoteles';
import Vista_Viajes_de_Negocios from './components/Vista_Viajes_de_Negocios/Vista_Viajes_de_Negocios';
import Vista_Desplazamientos_Diarios from './components/Vista_Desplazamientos_Diarios/Vista_Desplazamientos_Diarios';
import Vista_Turismo_de_Lujos from './components/Vista_Turismo_de_Lujos/Vista_Turismo_de_Lujos';
import ReactGA from "react-ga4";

/* chatbot */
import BubbleBot from './components/bubble_Bot_component/bubble_Bot.jsx';
import Alert from './components/alert_component/alert.jsx';
import ChatInterface from './components/chat_Interface_component/chat_Interface.jsx';
import './components/ContainerMain/page.module.css';

function App() {
  const [isChatOpen, setChatOpen] = useState(false);

  const handleBubbleClick = () => {
      setChatOpen(true);
  };

  const handleCloseChat = () => {
      setChatOpen(false);
  };

  const TRACKING_ID = "G-XYFJF5D692";
  ReactGA.initialize([
    {
      trackingId: TRACKING_ID,
    },
  ]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Home Page" });
  }, []);

  return (

    <div className="App">
      <BrowserRouter>
        <Routes>
          <>
            <Route path="/" element={
              <>
                {/* <Bienvenida></Bienvenida> */}
                <ReservaViajeProvider>
                  <Inicio />
                </ReservaViajeProvider>
              </>
            } />


            <Route path="/inicio" element={
              <ReservaViajeProvider>
                <Inicio />
              </ReservaViajeProvider>
            } />

            <Route path="/boton_pide_tu_servicio" element={
              <ReservaViajeProvider>
                <Boton_Pide_Tu_Servicio />
              </ReservaViajeProvider>
            } />


            <Route path="/reserva_tu_viaje" element={
              <ReservaViajeProvider>
                <ReservaTuViaje />
              </ReservaViajeProvider>
            } />

            <Route path="/luxxor" element={
              <ReservaViajeProvider>
                <MenuBotonLuxxor />
              </ReservaViajeProvider>
            } />

            <Route path="/galeria_fotos" element={
              <ReservaViajeProvider>
                <VistaGaleriaFotos />
              </ReservaViajeProvider>
            } />

            <Route path="/pedir_servicio" element={
              <ReservaViajeProvider>
                <VistaBotonPedirServicio />
              </ReservaViajeProvider>
            } />
            <Route path="/servicios" element={
              <ReservaViajeProvider>
                <VistaBotonPedirServicio />
              </ReservaViajeProvider>
            } />

            <Route path="/registro" element={
              <ReservaViajeProvider>
                <VistaBotonPartner />
              </ReservaViajeProvider>
            } />

            <Route path="/cotizacion_exitosa" element={<SolicitudCotizacion />} />
            <Route path="/solicitudreserva" element={<SolicitudReserva />} />


            <Route path="/cotizacion" element={
              <ReservaViajeProvider>
                <VistaBotonCotizacion />
              </ReservaViajeProvider>
            } />



            <Route path="/traslado_ejecutivo_recurrente" element={
              <ReservaViajeProvider>
                <Vista_Traslado_Ejecutivo_Recurrente />
              </ReservaViajeProvider>
            } />

            <Route path="/bodas_celebridades_en_general" element={
              <ReservaViajeProvider>
                <Vista_Bodas_Celebridades_En_General />
              </ReservaViajeProvider>
            } />


            <Route path="/eventos_empresariales_deportivos_entretenimiento" element={
              <ReservaViajeProvider>
                <Vista_Eventos_Empresariales_Deportivos_Entretenimiento />
              </ReservaViajeProvider>
            } />


            <Route path="/traslados_aeropuertos_y_hoteles" element={
              <ReservaViajeProvider>
                <Vista_Traslados_Aeropuertos_y_Hoteles />
              </ReservaViajeProvider>
            } />

            <Route path="/viajes_de_negocios" element={
              <ReservaViajeProvider>
                <Vista_Viajes_de_Negocios />
              </ReservaViajeProvider>
            } />


            <Route path="/desplazamientos_diarios" element={
              <ReservaViajeProvider>
                <Vista_Desplazamientos_Diarios />
              </ReservaViajeProvider>
            } />

            <Route path="/turismo_de_lujos" element={
              <ReservaViajeProvider>
                <Vista_Turismo_de_Lujos />
              </ReservaViajeProvider>
            } />



            <Route path="/traking" element={

              <GoogleMapsTracking origen="Abraham Lincoln coquimbo" destino="Aeropuerto la serena" />


            } />
          </>
        </Routes>
      </BrowserRouter>
      <div>
        <Alert />
        {isChatOpen ? (
          <ChatInterface onClose={handleCloseChat} />
        ) : (
          <BubbleBot onClick={handleBubbleClick} />
        )}
      </div>
      <ToastContainer />
    </div >
  );
}

export default App;


