import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import img_logo from '../../assets/img/Luxxor-Logo.png';
import img_barra_menu from '../../assets/img/icono_barra_menu.png';
import { Link } from 'react-router-dom';


const theme = createTheme({
    palette: {
        primary: {
            main: '#0AA74E',
            light: '#E4AEF2',
            dark: '#088C42',
            contrastText: '#fff',
        },
        secondary: {
            main: "#F24405",
        },
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    backgroundColor: 'black',
                    color: 'white',
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    padding: '24px',

                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: 'white',
                    borderRadius: '20px',
                    marginBottom: '10px'
                },
                h4: {
                    fontSize: '24px',
                    fontWeight: 'bold',
                    marginBottom: '16px',
                },
                h5: {
                    fontSize: '18px',
                    fontWeight: 'bold',
                    marginTop: '0',
                    marginBottom: '16px',
                },
                body1: {
                    span: {
                        color: '#fbbc02'
                    }
                }
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fbcc02', color: 'black', fontWeight: 'bold', borderRadius: '20px', textTransform: 'none',
                    '&:hover': {
                        backgroundColor: '#d7a205',
                    },

                },
            },
        },
    },
});

function Bienvenida() {
    return (
        <ThemeProvider theme={theme}>
            <div style={{ position: 'relative', backgroundColor: 'black' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <img src={img_barra_menu} alt="Header" style={{ width: '100px', marginBottom: '-50px' }} />
                    </Grid>
                </Grid>

                <Container maxWidth={false} >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <img src={img_logo} alt="Logo" style={{ width: '150px', marginBottom: '0' }} />
                            <Typography variant="h5">Luxxor</Typography>
                            <Typography variant="h4">¡Bienvenido a Luxxor!</Typography>
                            <Typography>
                                Descubre el estándar de <span>lujo en transporte</span> que va más allá
                                de tus expectativas. En Luxxor, fusionamos <span>elegancia, seguridad y comodidad</span> para
                                ofrecerte un viaje extraordinario. Desde eventos especiales hasta tus trayectos diarios, cada momento
                                es una oportunidad para experimentar el lujo en movimiento. ¡Bienvenido a una
                                nueva dimensión de viaje con Luxxor!
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Link to="/inicio">
                                <Button variant="contained">
                                    ¿A dónde vas?
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>

                </Container>
            </div>
        </ThemeProvider>
    );
}

export default Bienvenida;
