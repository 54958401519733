import { createTheme } from '@mui/material/styles';


export const main_inicio = createTheme({
    palette: {
        primary: {
            main: '#fbcc02',
            light: '#E4AEF2',
            dark: '#088C42',
            contrastText: '#fff',
        },
        secondary: {
            main: "#F24405",
        },
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    backgroundColor: 'black',
                    marginBottom: '-10px',
                    paddingBottom: '50px'
                },
            },
        },

        MuiTypography: {
            styleOverrides: {
                root: {

                },
                subtitle2: {
                    fontWeight: 'bold',
                    fontSize: '15px'

                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    background: '#f1f1f1',
                    border: '1',

                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    backgroundColor: '#f1f1f1', marginLeft: '8px', marginBottom: '10px'
                },
            },
        },

        MuiButton: {
            styleOverrides: {
                root: {
                    color: 'black',
                    backgroundColor: '#fbcc02',
                    marginRight: '8px',
                    textTransform: 'none',
                    '&:hover': {
                        backgroundColor: '#d7a205',
                    },
                    '&.Mui-disabled': {
                        backgroundColor: '#fbcc02', color: 'black',

                    },
                    lineHeight: "1.5"
                },
            },
        },
        MuiNativeSelect: {
            styleOverrides: {
               select: {
                 '&&.MuiInputBase-input': {
                    backgroundColor: '#f1f1f1',
                  }
                }
              }
            },
    },
});

