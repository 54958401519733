import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import img_logo from '../../assets/img/Luxxor-Logo.png';
import img_barra_menu from '../../assets/img/icono_barra_menu.png';
import icono_solicitud_reserva from '../../assets/img/icono_solicitud_reserva.png';
import icono_instagram from '../../assets/img/icono_instagram.png';
import icono_whatsapp from '../../assets/img/icono_whatsapp.png';

import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';


const theme = createTheme({
    palette: {
        primary: {
            main: '#0AA74E',
            light: '#E4AEF2',
            dark: '#088C42',
            contrastText: '#fff',
        },
        secondary: {
            main: "#F24405",
        },
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    backgroundColor: 'black',
                    color: 'white',
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    padding: '24px',

                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                    color: 'black',
                    borderRadius: '20px',

                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: 'white',
                    borderRadius: '20px',
                    marginBottom: '10px'
                },
                h4: {
                    fontSize: '24px',
                    fontWeight: 'bold',
                    marginBottom: '-92px',
                },
                h5: {
                    fontSize: '18px',
                    fontWeight: 'bold',
                    marginTop: '0',
                    marginBottom: '16px',

                },
                body1: {
                    span: {
                        color: '#fbbc02'
                    }
                }
            },
        },
    },
});
export default function SolicitudCotizacion() {

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate("/inicio");
    };

    const link_instagram = 'https://www.instagram.com/luxxor.cl/';
    const link_whatsapp = 'https://api.whatsapp.com/message/3BQ4NBSTHMQMM1?autoload=1&app_absent=0';

    const handleInstagramClick = () => {
        window.open(link_instagram, '_blank');
    };

    const handleWhatsappClick = () => {
        window.open(link_whatsapp, '_blank');
    };

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth={false} >
                <Grid container spacing={2}>
                    <Grid item xs={4} sm={4} lg={4} xl={4}>
                        <ArrowBackIcon onClick={handleGoBack} />
                    </Grid>

                    <Grid item xs={4} sm={4} lg={4} xl={4}>
                        <img src={img_barra_menu} style={{ width: '100px', textAlign: 'center' }} />
                    </Grid>

                    <Grid item xs={4} sm={4} lg={4} xl={4}></Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <img src={img_logo} alt="Logo" style={{ width: '150px', marginBottom: '0', marginTop: '60px' }} />
                        <Typography variant="h5">Luxxor</Typography>
                        <Typography variant="h4">¡Hemos recibido su cotizaci&oacute;n!</Typography>
                        <img src={icono_solicitud_reserva} alt="Logo" style={{ width: '100px', marginBottom: '0', marginBottom: '100px', marginTop: '100px' }} />
                        <Typography>
                            ¡Hemos recibido su cotizaci&oacute;n en linea. Nos pondremos en contacto pronto para brindarle m&aacute;s
                            informaci&oacute;n. <span>Est&eacute; atento a su correo electr&oacute;nico!</span>
                        </Typography>
                        <br></br>
                        <Typography>
                            Para m&aacute;s informaci&oacute;n cont&aacute;ctanos a trav&eacute;s del <span>+56989113329 </span>
                            o vis&iacute;tanos en Instagram como <span>@luxxor.cl</span>
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Link onClick={handleInstagramClick}>
                            <img src={icono_instagram} alt="Instagram" style={{ width: '50px', marginBottom: '0', padding: '10px' }} />
                        </Link>

                        <Link onClick={handleWhatsappClick}>
                            < img src={icono_whatsapp} alt="Whatsapp" style={{ width: '50px', marginBottom: '0', padding: '10px' }} />
                        </Link>
                    </Grid>

                </Grid>
            </Container>


        </ThemeProvider>
    )
}
