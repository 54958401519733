import React, { useState,useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Hidden from '@mui/material/Hidden';
import img_logo from '../../assets/img/luxxor_logo_texto.png'
import { ThemeProvider } from '@mui/material/styles';
import { estilo_navbar } from '../../components/Navbar/theme_navbar';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';

const styles = {
  logo: {
    width: '120px',
    height: 'auto',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
};


export default function Navbar() {

  return (
    <ThemeProvider theme={estilo_navbar}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{ backgroundColor: 'black' }}>
          <Toolbar variant="dense" sx={{ backgroundColor: 'black' }}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item>
                <Link to='/inicio'>
                  <Box>
                    <img src={img_logo} alt="Logo" style={styles.logo} />
                  </Box>
                </Link>
              </Grid>
              <Grid item>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
}