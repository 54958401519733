import { createContext, useContext, useEffect, useState } from 'react';

export const ReservaViajeContext = createContext();

export const ReservaViajeProvider = ({ children }) => {
    const [datosReserva, setDatosReserva] = useState({
        fecha: null,
        hora: null,
        personas: '',
        ubicacion: '',
        destino: '',
    }); 


    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedFormatDate, setSelectedFormatDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [location, setLocation] = useState('');
    const [numberOfPeople, setNumberOfPeople] = useState(1);
    const [location_destino, setLocation_destino] = useState('');

    const [servicioDefault, setServicioDefault] = useState('');

    const ReservaViajeContextValue = {
        datosReserva,
        setDatosReserva,
        selectedDate,
        setSelectedDate,
        selectedTime,
        setSelectedTime,
        location,
        setLocation,
        numberOfPeople,
        setNumberOfPeople,
        location_destino,
        setLocation_destino,
        selectedFormatDate, 
        setSelectedFormatDate,
        servicioDefault,
        setServicioDefault
    };

    return (
        <ReservaViajeContext.Provider value={ReservaViajeContextValue}>
            {children}
        </ReservaViajeContext.Provider>
    );

};


