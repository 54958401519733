import React, { useState, useEffect, useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import MinimizeIcon from '@mui/icons-material/Minimize';
import CloseIcon from '@mui/icons-material/Close';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import BubbleBot from '../bubble_Bot_component/bubble_Bot';
import styles from './chat_Interface.module.css';
import { renderBoldText, handleOpenConfirmDialog, handleConfirmClose, handleCancelClose, handleOptionClick, handleMoreQuestionsClick } from './chat_Interface';

function ChatInterface({ onClose }) {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [messages, setMessages] = useState([]);
  const messagesStartRef = useRef(null);
  const messagesEndRef = useRef(null);
  const [chatStartTime] = useState(new Date());
  const [lastMessageSender, setLastMessageSender] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const optionsPerPage = 8;

  useEffect(() => {
    const initialMessage = {
      id: 0,
      text: '✨¡Bienvenido al asistente virtual de Luxxor!✨ Estoy aquí para ayudarte en lo que necesites.',
      options: null,
      isUserMessage: false,
    };

    const optionsMessage = {
      id: 1,
      text: 'Por favor, elige una de las siguientes opciones:',
      options: [
        '¿Cómo reservar?',
        'Formas de pago',
        'Capacidad de la van',
        'Tarifas',
        'Más de un trayecto',
        'Vehículos disponibles',
        'Anticipación de reserva',
        'Valores día y noche',
        'Viaje seguro',
        'Plazo de cancelación',
        'Beneficios de viajar en Luxxor',
        'Como puedo ser partner',
        'Beneficios de registro',
        'Convenio Luxxor Empresas',
        'Factura',
        'Tengo otras dudas'
      ],
      isUserMessage: false,
      timestamp: chatStartTime.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' }),
    };

    setMessages([initialMessage, optionsMessage]);
  }, [chatStartTime]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const optionsMessage = messages.find(msg => msg.id === 1);
  const displayedOptions = optionsMessage?.options.slice(pageIndex * optionsPerPage, (pageIndex + 1) * optionsPerPage);

  if (isMinimized) {
    return (
      <BubbleBot onClick={() => setIsMinimized(false)} />
    );
  }

  const lastBotMessageIndexAfter3 = messages.reduce((acc, message, index) => {
    return !message.isUserMessage && index >= 3 ? index : acc;
  }, -1);

  const handleOptionClickWrapper = async (option) => {
    await handleOptionClick(option, {
      setLoading,
      setMessages,
      setLastMessageSender,
      messages,
      renderBoldText,
    });
  };

  const handleMoreQuestionsClickWrapper = () => {
    handleMoreQuestionsClick(messagesStartRef, setIsButtonVisible);
  };

  const handleOpenConfirmDialogWrapper = () => {
    handleOpenConfirmDialog(setShowConfirmDialog);
  };

  const handleConfirmCloseWrapper = () => {
    handleConfirmClose(onClose, setShowConfirmDialog);
  };

  const handleCancelCloseWrapper = () => {
    handleCancelClose(setShowConfirmDialog);
  };

  return (
    <div className={styles['chat-interface']}>
      <div className={styles['chat-header']}>
        <h3 className={styles['chat-title']}>Asistente Virtual</h3>
        <IconButton onClick={() => {
          setIsMinimized(true);
        }}>
          <MinimizeIcon className={styles['chat-minimize-button']} />
        </IconButton>
        <IconButton onClick={handleOpenConfirmDialogWrapper}>
          <CloseIcon className={styles['chat-close-button']} />
        </IconButton>
      </div>
      <div className={styles['chat-messages']}>
        <div ref={messagesStartRef} />
        <div className={styles['chat-start-date']}>
          {chatStartTime.toLocaleDateString('es-ES', { day: 'numeric', month: 'long', year: 'numeric' })}
        </div>
        {messages.map((message, index) => (
          <div key={index} className={styles['container-message']}>
            <div className={message.isUserMessage ? styles['sender-container'] : styles['receiver-container']}>
              <div className={message.isUserMessage ? styles['sender'] : styles['receiver']}>
                <p
                  className={styles['message-text']}
                  dangerouslySetInnerHTML={{ __html: message.text }}
                ></p>
                {index === 1 && displayedOptions && (
                  <div className={styles['message-options']}>
                    {displayedOptions.map((option, idx) => (
                      <div key={idx} className={styles['option-item']} onClick={() => handleOptionClickWrapper(option)}>
                        {option}
                      </div>
                    ))}
                    <div className={styles['pagination-controls']}>
                      {pageIndex > 0 ? (
                        <IconButton
                          onClick={() => setPageIndex(pageIndex - 1)}
                          aria-label="Página anterior"
                        >
                          <NavigateBeforeIcon />
                        </IconButton>
                      ) : (
                        <span className={styles['invisible-button']}></span>
                      )}
                      <div className={styles['page-number']}>
                        <span>{pageIndex + 1}</span>
                      </div>
                      {pageIndex + 1 < Math.ceil(optionsMessage.options.length / optionsPerPage) ? (
                        <IconButton
                          onClick={() => setPageIndex(pageIndex + 1)}
                          aria-label="Página siguiente"
                        >
                          <NavigateNextIcon />
                        </IconButton>
                      ) : (
                        <span className={styles['invisible-button']}></span>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {!message.isUserMessage && (
                <span className={styles['receiver-hour']}>{message.timestamp}</span>
              )}
              {message.isUserMessage && (
                <span className={styles['sender-hour']}>{message.timestamp}</span>
              )}
              {!message.isUserMessage && index === lastBotMessageIndexAfter3 && (
                <div className={styles['more-question-container']}>
                  <button className={styles['more-question']} onClick={handleMoreQuestionsClickWrapper}>
                    ¿Tienes más dudas?
                  </button>
                  <button className={styles['no-more-question']} onClick={handleOpenConfirmDialogWrapper}>
                    Finalizar chat
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
        {loading && (
          <div className={`${styles['receiverContainer']} ${styles['loader']}`}>
            <div className={styles['typingAnimation']}>
              <span className={styles['dot']}></span>
              <span className={styles['dot']}></span>
              <span className={styles['dot']}></span>
            </div>
          </div>
        )}
      </div>
      {showConfirmDialog && (
        <div className={styles['confirm-dialog']}>
          <p>¿Estás seguro de querer finalizar este chat?</p>
          <button className={styles['confirm-yes-button']} onClick={handleConfirmCloseWrapper}>Sí</button>
          <button className={styles['confirm-no-button']} onClick={handleCancelCloseWrapper}>No</button>
        </div>
      )}
    </div>
  );
}

export default ChatInterface;
