import { createTheme } from '@mui/material/styles';

export const themeslider = createTheme({
    palette: {
        primary: {
            main: '#0AA74E',
            light: '#E4AEF2',
            dark: '#088C42',
            contrastText: '#fff',
        },
        secondary: {
            main: "#F24405",
        },
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    backgroundColor: 'black',
                    marginBottom: '-10px',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: 'white',
                    zIndex: 1,
                    textAlign: 'center',
                    fontSize:'10px',
                    
                   
                },
             
            },
        },
    },
});