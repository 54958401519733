import { createTheme } from '@mui/material/styles';

export const estilo_reservar_viaje = createTheme({
    palette: {
        primary: {
            main: '#0AA74E',
            light: '#E4AEF2',
            dark: '#088C42',
            contrastText: '#fff',
        },
        secondary: {
            main: "#F24405",
        },
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fbbc02',
                    textAlign: 'center',
                    padding: '20px',
                    maxWidth: 'none',
                    marginBottom: '-20px'
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    /*    backgroundColor: '#fbcc02',
                       color: 'black',
                       fontWeight: '500',
                       borderRadius: '500px 500px 500px 500px;',
                       textTransform: 'none',
                       fontsize: '24px', */

                    fontSize: '24px',
                    fontWeight: '500',
                     backgroundColor:  '#333333',
               /*      background: 'linear-gradient(45deg, black, #fbbc02)',   
                    border: '3px solid white', */
                    /* borderRadius: '500px 500px 500px 500px', */
                    borderRadius: '10px',
                    textTransform: 'none',
                    padding: '20px 40px',
                    lineHeight: '1',


                    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
                    transition: 'transform 0.2s, box-shadow 0.2s',

                    '@media (max-width:600px)': { // xs
                        fontSize: '18px',

                    },
                    '@media (min-width:601px) and (max-width:960px)': { // sm
                        fontSize: '20px',

                    },
                    '@media (min-width:961px) and (max-width:1280px)': { // md
                        fontSize: '22px',

                    },
                    '@media (min-width:1281px) and (max-width:1920px)': { // lg
                        fontSize: '24px',

                    },
                    '@media (min-width:1921px)': { // xl
                        fontSize: '24px',

                    },


                    '&:hover': {
                        backgroundColor: '#555555',
                        transform: 'scale(1.1)',
                        boxShadow: '0px 5px 12px rgba(0, 0, 0, 0.24)',
                    },

                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&.MuiInputBase-root': {
                        backgroundColor: '#fffae4',
                    },
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    width: "14px",
                    height: "14px",
                    paddingRight: "5px",
                    color: "#000000",
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    position: "sticky"

                },
            },
        },
    },
});