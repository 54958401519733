import React from 'react'
import SliderInicio from '../Slider/SliderInicio'
import ContainerReservarViaje from '../ContainerReservar_Viaje/ContainerReservarViaje'
import FormularioPartner from '../FormularioPartner/FormularioPartner'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'

export default function VistaBotonPartner() {
  return (
    <div>
      <Navbar />
      <SliderInicio />
      <ContainerReservarViaje />
      <FormularioPartner />
      <Footer />
    </div>
  )
}
