import React from 'react'
import SliderInicio from '../Slider/SliderInicio';
import ContainerReservarViaje from '../ContainerReservar_Viaje/ContainerReservarViaje';
import BotonLuxxor from '../BotonLuxxor/BotonLuxxor';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';

export default function VistaBotonLuxxor() {
  return (
    <div>
      <Navbar />
      <SliderInicio />
      <ContainerReservarViaje />
      <BotonLuxxor />
      <Footer />
    </div>
  )
}
