import { createTheme } from '@mui/material/styles';


export const main_inicio = createTheme({
    palette: {
        primary: {
            main: '#0AA74E',
            light: '#E4AEF2',
            dark: '#088C42',
            contrastText: '#fff',
        },
        secondary: {
            main: "#F24405",
        },
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    backgroundColor: 'black',
                    paddingBottom: '50px',
                    minHeight: '100vh',
              
                  
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    padding: '20px',
                    textAlign: 'center',
                    marginBottom: '16px',
                    backgroundColor: '#181819',
                    color: 'white',
                    alignContent: 'center',
                    marginTop:'40px',
                    height: '85%', // Valor predeterminado
                    width: '70%', // Valor predeterminado
                    '@media (max-width:600px)': { // xs
                        height: '60%',
                        width: '70%',
                    },
                    '@media (min-width:601px) and (max-width:960px)': { // sm
                        height: '85%',
                        width: '80%',
                    },
                    '@media (min-width:961px) and (max-width:1280px)': { // md
                        height: '85%',
                        width: '80%',
                    },
                    '@media (min-width:1281px) and (max-width:1920px)': { // lg
                        height: '85%',
                        width: '80%',
                    },
                    '@media (min-width:1921px)': { // xl
                        height: '90%',
                        width: '40%',
                    },
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {

                },
                subtitle2: {
                    fontWeight: 'bold',
                    marginTop: '10px',
                },
            },
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    
                    justifyContent: 'center',
                    display: 'flex',
                   
                },
                item: {
                    marginBottom: '24px',
                },
              
            },
        },
    },
});