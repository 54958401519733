import { Box, Container, Grid, Typography } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";
import icono_whatsapp from '../../assets/rrss/whatsapp.png';
import icono_instagram from '../../assets/rrss/instagram.png';
import icono_email from '../../assets/rrss/email.png';
import icono_telefono from '../../assets/rrss/Phone.png';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import Hidden from '@mui/material/Hidden';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
export default function Footer() {

  const link_instagram = 'https://www.instagram.com/luxxor.cl/';
  const link_whatsapp = 'https://api.whatsapp.com/message/3BQ4NBSTHMQMM1?autoload=1&app_absent=0';

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          maxHeight: '55px',
          position: 'fixed',
          bottom: 0,
          background: '#fbbc02',
          height: '55px',
          display: 'flex',
          justifyContent: 'space-between', // Para alinear los elementos a los extremos
          alignItems: 'center', // Para centrar verticalmente
        }}
      >

        {/*  <Box sx={{ m: '5px', color: '#f3f3f3', display: 'flex', gap: '5px' }}>
          <a href={link_whatsapp} target="_blank">
            <img src={icono_whatsapp} alt="WhatsApp" style={{ width: '24px', height: '24px' }} />
          </a>
          <a href={link_instagram} target="_blank">
            <img src={icono_instagram} alt="Instagram" style={{ width: '24px', height: '24px' }} />
          </a>
        </Box>
 */}
        {/* esto es una prueba */}
        {/*  <Box>
          <Box sx={{ m: '2px', color: '#f3f3f3', display: 'flex', gap: '5px' }}>
            <img src={icono_telefono} alt="WhatsApp" style={{ width: '24px', height: '24px' }} />
            <Typography variant="body1" sx={{ fontSize: '9px', fontWeight: 'bold', color: 'black', marginTop: '5px'}}>
              +56989113329
            </Typography>
            <img src={icono_email} alt="WhatsApp" style={{ width: '24px', height: '24px' }} />
            <Typography variant="body1" sx={{ fontSize: '9px', fontWeight: 'bold', color: 'black',marginTop: '5px' }}>
              contacto@luxxor.cl
            </Typography>
          </Box>

          <Box sx={{ m: '2px', color: '#f3f3f3', display: 'flex', gap: '5px' }}>
            <a href={link_whatsapp} target="_blank">
              <img src={icono_whatsapp} alt="WhatsApp" style={{ width: '24px', height: '24px' }} />
            </a>
            <a href={link_instagram} target="_blank">
              <img src={icono_instagram} alt="Instagram" style={{ width: '24px', height: '24px' }} />
            </a>
          </Box>
        </Box> */}
        {/* fin de la prueba */}

        {/*         <Hidden xsDown smDown>
          <Box sx={{ m: '5px', color: '#f3f3f3' }}>
            <Typography
              variant="subtitle1"
              align="center"
              sx={{
                fontWeight: 'bold',
                color: 'black',
                fontSize: {
                  lg: 9,
                  md: 9,
                  sm: 9,
                  xs: 9,
                },

              }}
            >
              Luxxor © 2023 Transporte de pasajeros VIP & Ejecutivo
            </Typography>
          </Box>
        </Hidden>

        <Hidden smUp mdUp lgUp xlUp>
          <Box sx={{ m: 'auto', color: '#f3f3f3', display: 'flex', gap: '5px' }}>
            <img src={icono_telefono} alt="WhatsApp" style={{ width: '24px', height: '24px' }} />
            <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: 'bold', color: 'black', marginTop: '5px' }}>
              +56989113329
            </Typography>
            <img src={icono_email} alt="WhatsApp" style={{ width: '24px', height: '24px' }} />
            <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: 'bold', color: 'black', marginTop: '5px' }}>
              contacto@luxxor.cl
            </Typography>
          </Box>
        </Hidden>


        <Hidden xsDown smDown >
          <Box sx={{ m: '2px', color: '#f3f3f3', display: 'flex', gap: '5px' }}>
            <img src={icono_telefono} alt="WhatsApp" style={{ width: '24px', height: '24px' }} />
            <Typography variant="body1" sx={{ fontSize: '9px', fontWeight: 'bold', color: 'black', marginTop: '5px' }}>
              +56989113329
            </Typography>
            <img src={icono_email} alt="WhatsApp" style={{ width: '24px', height: '24px' }} />
            <Typography variant="body1" sx={{ fontSize: '9px', fontWeight: 'bold', color: 'black', marginTop: '5px' }}>
              contacto@luxxor.cl
            </Typography>
          </Box>
        </Hidden> */}


        <Box sx={{ m: 'auto', color: '#f3f3f3', display: 'flex', gap: '5px' }}>
          <img src={icono_telefono} alt="WhatsApp" style={{ width: '24px', height: '24px' }} />
          <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', color: 'black', marginTop: '5px' }}>
            +56989113329
          </Typography>
          <img src={icono_email} alt="WhatsApp" style={{ width: '24px', height: '24px' }} />
          <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', color: 'black', marginTop: '5px' }}>
            contacto@luxxor.cl
          </Typography>
        </Box>


      </Container>
    </>
  );

}
