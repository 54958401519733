import React, { useState, useRef } from 'react'
import { Typography, Grid, Container, TextField, MenuItem, Button, Paper, FormControl, TextareaAutosize, Box, InputAdornment, NativeSelect, Alert } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { main_inicio } from '../../components/FormReservarViaje/theme_form_reservar_viaje';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EventIcon from '@mui/icons-material/Event';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import BusinessIcon from '@mui/icons-material/Business';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from 'react-select';

import { Link } from 'react-router-dom';
import axios from 'axios';

import "./FormularioPartener.css";
import ReCAPTCHA from 'react-google-recaptcha';

import { saveAs } from 'file-saver';

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
const API_LUXXOR_HOST = process.env.REACT_APP_API_LUXXOR_HOST;

export default function FormularioPartner() {

    const select_options = [
        { value: "", label: "[seleccione usuario]" },
        { value: 'Cliente', label: 'Cliente' },
        { value: 'Partner', label: 'Partner' },
        { value: 'Proveedor', label: 'Proveedor' },
    ];

    const [selectedUser, setSelectedUser] = useState('');
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [correo_electronico, setCorreoElectronico] = useState('');
    const [telefono, setTelefono] = useState('');
    const [direccion, setDireccion] = useState('');
    const [rut, setRut] = useState('');
    const [selectedTipoUsuario, setTipoUsuario] = useState(null);

    const [mensajeAlertaError, setMensajeAlertaError] = useState(false);
    const [mensajeAlertaSuccess, setMensajeAlertaSuccess] = useState(false);

    const captcha = useRef(null);
    const [captchaIsDone, setCaptchaIsDone] = useState(true);

    const handleRecaptcha = () => {
        setCaptchaIsDone(true);
    };

    const handleUserChange = (e) => {
        const index = select_options.findIndex(option => option.value === e.value);
        const tipo_usuario = select_options[index];

        setTipoUsuario(select_options[index]);
        setSelectedUser(tipo_usuario.value);
        showUserDescription(tipo_usuario.value);
        setMensajeAlertaError(false);
        setMensajeAlertaSuccess(false);

    };

    const handleChangeNombre = (e) => {
        setNombre(e.target.value);
        setMensajeAlertaError(false);
        setMensajeAlertaSuccess(false);
    };

    const handleChangeApellido = (e) => {
        setApellido(e.target.value);
        setMensajeAlertaError(false);
        setMensajeAlertaSuccess(false);
    };

    const handleChangeCorreoElectronico = (e) => {
        setCorreoElectronico(e.target.value);
        setMensajeAlertaError(false);
        setMensajeAlertaSuccess(false);
    };

    const handleChangeTelefono = (e) => {
        setTelefono(e.target.value);
        setMensajeAlertaError(false);
        setMensajeAlertaSuccess(false);
    };

    const handleChangeRut = (e) => {
        setRut(e.target.value);
        setMensajeAlertaError(false);
        setMensajeAlertaSuccess(false);
    };

    const handleChangeDireccion = (e) => {
        setDireccion(e.target.value);
        setMensajeAlertaError(false);
        setMensajeAlertaSuccess(false);
    };



    const showUserDescription = (user) => {
        let description = '';

        switch (user) {
            case 'Cliente':
                description = 'Como cliente de Luxxor, experimentarás el pináculo del lujo y la comodidad en cada viaje. Desde la elegante interfaz de la aplicación que facilita la reserva hasta la llegada en vehículos de alta gama, ser parte de Luxxor significa sumergirse en una experiencia de transporte sin igual. Disfrutarás de la atención personalizada, la versatilidad de servicios y la tranquilidad de saber que cada detalle de tu viaje está cuidadosamente gestionado. Luxxor no es simplemente un servicio de transporte; es tu entrada a un mundo donde la excelencia y el estilo convergen para hacer que cada momento sea inolvidable.';
                toast(description, {
                    position: "bottom-center",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                break;
            case 'Partner':
                description = 'Convertirte en un partner de Luxxor significa formar parte de una red exclusiva de colaboradores estratégicos. Ya sea como un establecimiento de lujo, un proveedor de servicios complementarios o una entidad corporativa, ser partner de Luxxor implica una asociación que va más allá de lo convencional. Colaborar con Luxxor te brinda la oportunidad de ofrecer experiencias excepcionales a los clientes compartidos, beneficiándote de la reputación de calidad y lujo que Luxxor aporta a cada interacción. La asociación con Luxxor es sinónimo de distinción y excelencia.';
                toast(description, {
                    position: "bottom-center",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                break;
            case 'Proveedor':
                description = 'Ser proveedor para Luxxor implica ser parte fundamental de la cadena de servicio que garantiza la excelencia en cada detalle. Desde vehículos de lujo hasta servicios adicionales, ser proveedor Luxxor significa mantener los más altos estándares de calidad. La colaboración con Luxxor te conecta con una red exclusiva de clientes y partners, ofreciéndote la oportunidad de destacar y ser reconocido por tu contribución a la experiencia distintiva que Luxxor ofrece. La asociación con Luxxor es un sello de calidad que eleva tu posición en el mercado de servicios de lujo.';
                toast(description, {
                    position: "bottom-center",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                break;
            default:
                description = '';
        }
        //alert(description);
    };


    const handleSolicitarPartnership = async () => {
        setMensajeAlertaError(false);
        setMensajeAlertaSuccess(false);
        
        try {

            if (!captchaIsDone) {
                return;
            }

            if (!selectedUser || !nombre || !apellido || !correo_electronico || !telefono || !rut || !direccion) {
                setMensajeAlertaError("Por favor, completa todos los campos.");
                return;
            }

            const tokenReCaptcha = captcha.current.getValue();

            const datosSolicitud = {
                tipo_usuario: selectedUser,
                nombre,
                apellido,
                correo_electronico,
                telefono,
                rut,
                direccion,
                tokenReCaptcha: tokenReCaptcha
            };
            
            const response = await axios.post(`${API_LUXXOR_HOST}/luxxor/crear`, datosSolicitud);
            
            if (selectedUser === 'Partner' && response.data.success) {

                const binaryData = atob(response.data.pdfBase64);
                const arrayBuffer = new ArrayBuffer(binaryData.length);
                const uint8Array = new Uint8Array(arrayBuffer);

                for (let i = 0; i < binaryData.length; i++) {
                    uint8Array[i] = binaryData.charCodeAt(i);
                }

                const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                const blobUrl = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = response.data.fileoutput;
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(blobUrl);

            }

            if (response.data.success) {
                setMensajeAlertaSuccess(response.data.message);
            } else {
                setMensajeAlertaError(response.data.message)
            }

        } catch (error) {
            
            toast.error(error, {
                position: "top-center",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    };





    return (
        <ThemeProvider theme={main_inicio}>
            <Container maxWidth={false} style={{ marginTop: '20px', textAlign: 'center' }}>

                <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ mb: "40px" }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography
                            variant="a"
                            sx={{
                                color: "white",
                                fontSize: {
                                    xl: 16,
                                    lg: 15,
                                    md: 14,
                                    sm: 14,
                                    xs: 14,
                                },
                            }}
                        >
                            ¡Reg&iacute;strate en Luxxor para obtener incre&iacute;bles beneficios como cliente, acumula puntos Luxxor
                            al recomendarnos con tu c&oacute;digo partner o trabaja con nosotros! Reg&iacute;strate ahora y comienza a vivir
                            el lujo en cada trayecto.
                        </Typography>
                    </Grid>
                </Grid>

                {mensajeAlertaError && (
                    <>
                        <Alert variant="filled" severity="error" sx={{ mb: "20px" }}>
                            {mensajeAlertaError}
                        </Alert>
                    </>
                )}

                {mensajeAlertaSuccess && (
                    <>
                        <Alert variant="filled" severity="success" sx={{ mb: "20px" }}>
                            {mensajeAlertaSuccess}
                        </Alert>
                    </>
                )}

                <Grid container spacing={2} xs={12} sm={12} md={10} lg={8} xl={8} sx={{ m: "auto" }}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="h6" sx={{ color: 'white', fontSize: '15px', textAlign: 'center' }}>
                            Nombre
                        </Typography>

                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            value={nombre}
                            onChange={handleChangeNombre}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircleIcon />
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                        />
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="h6" sx={{ color: 'white', fontSize: '15px', textAlign: 'center' }}>
                            Apellido
                        </Typography>

                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            value={apellido}
                            onChange={handleChangeApellido}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircleIcon />
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                        />
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="h6" sx={{ color: 'white', fontSize: '15px', textAlign: 'center' }}>
                            Correo Electr&oacute;nico
                        </Typography>

                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            value={correo_electronico}
                            onChange={handleChangeCorreoElectronico}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon />
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                        />
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="h6" sx={{ color: 'white', fontSize: '15px', textAlign: 'center' }}>
                            N&uacute;mero de tel&eacute;fono
                        </Typography>

                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            type='tel'
                            value={telefono}
                            onChange={handleChangeTelefono}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PhoneIcon />
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                        />
                    </Grid>


                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="h6" sx={{ color: 'white', fontSize: '15px', textAlign: 'center' }}>
                            Rut
                        </Typography>

                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            value={rut}
                            onChange={handleChangeRut}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AssignmentIndIcon />
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                        />
                    </Grid>



                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="h6" sx={{ color: 'white', fontSize: '15px', textAlign: 'center' }}>
                            Tipo de Usuario
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Select
                            id="id-servicio"
                            options={select_options}
                            onChange={handleUserChange}
                            value={selectedTipoUsuario}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#fbcc02',
                                    primary: '#d7a205',
                                },
                            })}
                        />
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant="h6" sx={{ color: 'white', fontSize: '15px', textAlign: 'center' }}>
                            Direcci&oacute;n
                        </Typography>

                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            value={direccion}
                            onChange={handleChangeDireccion}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LocationOnIcon />
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                        />
                    </Grid>

                    <Box style={{ display: 'flex', marginBottom: "20px", marginTop: "20px", width: '100%' }}>
                        <ReCAPTCHA
                            ref={captcha}
                            sitekey={RECAPTCHA_SITE_KEY}
                            theme='dark'
                            onChange={handleRecaptcha}
                            style={{ margin: 'auto' }}
                        />
                    </Box>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Link >
                            <Button variant="contained" onClick={() => handleSolicitarPartnership()} style={{ borderRadius: '20px', fontWeight: 'bold', padding: '6px 16px', marginBottom: '10px' }}>
                                Solicitar Registro
                            </Button>
                        </Link>
                    </Grid>

                </Grid>
            </Container>
        </ThemeProvider >

    )
}
