export const capitalize = (frase) => {
    if (typeof frase !== 'string') {
        // Puedes devolver un valor predeterminado o lanzar un error
        return ''; // Valor predeterminado
        // O lanzar un error:
        // throw new Error('La función capitalize espera una cadena como argumento');
    }
    return frase
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}
