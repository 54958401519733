import { createTheme } from '@mui/material/styles';


export const estilo_navbar = createTheme({
    palette: {
        primary: {
            main: '#0AA74E',
            light: '#E4AEF2',
            dark: '#088C42',
            contrastText: '#fff',
        },
        secondary: {
            main: "#F24405",
        },
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                   
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    minHeight:'70px'
                },
            },
        },
      

    },
});