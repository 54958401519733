import React, { useContext, useState } from "react";
import { Typography, Grid, Paper } from '@mui/material';

import { Container } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { main_inicio } from '../../components/ContainerMain/theme_container_main';
import icono_luxxor from '../../assets/img/icono_luxxor.png';
import icono_patner from '../../assets/img/icono_patner.png';
import icono_servicio from '../../assets/img/icono_pedir_servicio.png';
import icono_cotizar from '../../assets/img/icono_cotizar.png';
import icono_wts from '../../assets/rrss/whatsapp_main.png'
import icono_instagram from '../../assets/rrss/instagram_main.png'
import { Link } from 'react-router-dom';



/* import { ReservaViajeContext } from "../../contexts/ReservaViajeContext"; */

const fontSize = {
    xs: '13px',
    sm: '15px',
    md: '18px',
    lg: '18px',
    xl: '20px',
};



export default function ContainerMain() {
    
    /*  const { isBotonLuxxor, setIsBottonLuxxor } = useContext(ReservaViajeContext);
     const handleIrBotonLuxxor = (estado) => {
         setIsBottonLuxxor(estado);
     }; */

    const link_instagram = 'https://www.instagram.com/luxxor.cl/';
    const link_whatsapp = 'https://api.whatsapp.com/message/3BQ4NBSTHMQMM1?autoload=1&app_absent=0';


    return (
        <ThemeProvider theme={main_inicio}>
            <Container maxWidth={false} style={{ marginTop: '20px' }}>
                <Grid container spacing={0}  >
                    <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                        <Paper elevation={3}>
                            <a href={link_instagram} target="_blank">
                                <img src={icono_instagram} alt="Instagram" style={{ width: '50%', maxWidth: '90px', height: 'auto' }} />
                            </a>
                            <Typography variant="subtitle2" sx={{ fontSize: fontSize }}>
                                Instagram
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                        <Paper elevation={3}>
                            <a href={link_whatsapp} target="_blank">
                                <img src={icono_wts} alt="Whatsapp" style={{ width: '50%', maxWidth: '90px', height: 'auto' }} />
                            </a>
                            <Typography variant="subtitle2" sx={{ fontSize: fontSize }}>
                                whatsapp
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                        <Paper elevation={3} >
                            <Link to='/luxxor'>
                                <img src={icono_luxxor} alt="Categoria ¿Quiénes somos?" style={{ width: '50%', maxWidth: '90px', height: 'auto' }} />
                            </Link>
                            <Typography variant="subtitle2" sx={{ fontSize: fontSize }}>
                                ¿Qui&eacute;nes somos?
                            </Typography>
                        </Paper>
                    </Grid>

                    {/*       <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Paper elevation={3}>
                            <Link to='/pedir_servicio'>
                                <img src={icono_servicio} alt="Categoría Pedir Servicio" style={{ width: '50%', maxWidth: '75px', height: 'auto' }} />
                            </Link>
                            <Typography variant="subtitle2" sx={{ fontSize: fontSize }}>
                                Pide tu Servicio
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Paper elevation={3}>
                            <Link to='/cotizacion'>
                                <img src={icono_cotizar} alt="Categoría Cotizar" style={{ width: '50%', maxWidth: '75px', height: 'auto' }} />
                            </Link>
                            <Typography variant="subtitle2" sx={{ fontSize: fontSize }}>
                                Cotiza Ahora
                            </Typography>
                        </Paper>
                    </Grid> */}
                    <Grid item xs={6} sm={6} md={6} lg={3} xl={3} >
                        <Paper elevation={3} >
                            <Link to='/registro'>
                                <img src={icono_patner} alt="Categoría Patner" style={{ width: '50%', maxWidth: '90px', height: 'auto' }} />
                            </Link>
                            <Typography variant="subtitle2" sx={{ fontSize: fontSize }}>
                                Reg&iacute;strate
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
                
            </Container>

        </ThemeProvider>



    )
}
