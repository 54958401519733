import { Button, Container, Grid, TextField, InputAdornment, Paper } from '@mui/material'
import React, { useContext, useState } from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import PeopleIcon from '@mui/icons-material/People';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { estilo_reservar_viaje } from './theme_reservar_viaje';

import { ReservaViajeContext } from "../../contexts/ReservaViajeContext";

import moment from 'moment-timezone';

export default function ContainerReservarViaje() {

    const { setNumberOfPeople,
        setSelectedDate,
        setSelectedTime,
        setLocation,
        setLocation_destino,
        selectedDate,
        selectedTime,
        numberOfPeople,
        location_destino,
        location } = useContext(ReservaViajeContext);



    const handleNumberChange = (event) => {
        /*  setNumberOfPeople(parseInt(event.target.value, 10) || 1); */
        if (event.target.value >= 0) {
            setNumberOfPeople(event.target.value);
        }
    };

    const handleDateChange = (event) => {
        const newSelectedDate = event.target.value;

        setSelectedDate(newSelectedDate);
    };



    const handleTimeChange = (event) => {
        const newSelectedTime = event.target.value;

        setSelectedTime(newSelectedTime);
    };

    const handleLocationChange = (event) => {
        setLocation(event.target.value);
    }

    const handleLocationDestinoChange = (event) => {
        setLocation_destino(event.target.value);
    };


    return (
        <ThemeProvider theme={estilo_reservar_viaje}>
            <Container style={{ color: 'black', maxWidth: 'none' }}>
                <Grid container spacing={2} columns={12} justifyContent="center" alignItems="center">
                    {/*   <Grid item xs={12} md={2} lg={2} xl={2}>
                        <Paper> */}
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker label="Fecha" sx={{ width: '100%' }} />
                            </LocalizationProvider> */}
                    {/*        <TextField
                                size='small'
                                fullWidth
                                variant="outlined"
                                type="date"
                                value={selectedDate}
                                label="Fecha"
                                onChange={handleDateChange}
                                InputLabelProps={{
                                    shrink: true,
                                    sx: { display: { xs: 'block', sm: 'block', md: 'block', lg: 'block', xl: 'block' } }
                                }}
                            />
                        </Paper>
                    </Grid> */}

                    {/* <Grid item xs={12} md={2} lg={2} xl={2}> */}
                    {/* <Paper>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker label="Hora" sx={{ width: '100%' }} />
                            </LocalizationProvider>
                        </Paper> */}
                    {/*    <Paper>
                            <TextField
                                size='small'
                                fullWidth
                                variant="outlined"
                                type="time"
                                label="Hora"
                                value={selectedTime}
                                onChange={handleTimeChange}
                                InputLabelProps={{
                                    shrink: true,
                                    sx: { display: { xs: 'block', sm: 'block', md: 'block', lg: 'block', xl: 'block' } }
                                }}

                            />
                        </Paper>
                    </Grid> */}

                    {/*   <Grid item xs={12} md={2} lg={2} xl={2}>
                        <Paper>
                            <TextField
                                size='small'
                                fullWidth
                                variant="outlined"
                                label="Personas"
                                type="number"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <PeopleIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                value={numberOfPeople}
                                onChange={handleNumberChange}
                                sx={{ width: '100%' }}
                            />
                        </Paper>
                    </Grid>
 */}
                    {/*  <Grid item xs={12} md={2} lg={2} xl={2}>
                        <Paper>
                            <TextField
                                size='small'
                                fullWidth
                                variant="outlined"
                                label="¿Dónde estás?"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <LocationOnIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                value={location}
                                onChange={handleLocationChange}
                                sx={{ width: '100%' }}
                            />
                        </Paper>
                    </Grid> */}

                    {/* <Grid item xs={12} md={2} lg={2} xl={2}>
                        <Paper>
                            <TextField
                                size='small'
                                fullWidth
                                variant="outlined"
                                label="¿A dónde te llevamos?"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <DriveEtaIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                value={location_destino}
                                onChange={handleLocationDestinoChange}
                                sx={{ width: '100%' }}
                            />
                        </Paper>
                    </Grid>
 */}

                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        {/* <Link to='/reserva_tu_viaje'> */}
                        <a href="https://order.geturvan.com/luxxor">
                            <Button variant="contained" /* onClick={() => handleIrFormulario(true)} */>
                                Reserva Online
                            </Button>
                        </a>
                        {/*    </Link> */}
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>

    )
}
