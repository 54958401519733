import React, { useContext, useState } from "react";
import ContainerReservarViaje from '../ContainerReservar_Viaje/ContainerReservarViaje';
import SliderInicio from '../Slider/SliderInicio';
import ContainerMain from '../ContainerMain/ContainerMain';
import FormularioReservarViaje from '../FormReservarViaje/FormularioReservarViaje';
import BotonLuxxor from "../BotonLuxxor/BotonLuxxor";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import NavbarInicio from "../Navbar_Inicio/Navbar_Inicio";
import '@mui/material';

/* import { ReservaViajeContext } from "../../contexts/ReservaViajeContext"; */

export default function Inicio() {
    /*     const { isRevervaFormulario, setIsRevervaFormulario, isBotonLuxxor, setIsBottonLuxxor } = useContext(ReservaViajeContext); */
    return (
        <div>
            <NavbarInicio />
            <SliderInicio />
            <ContainerReservarViaje />
            <ContainerMain />
            <Footer />
        </div >
    );
}
