import React, { useState } from 'react';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { Button, Modal as ModalMaterial, Box } from '@mui/material';
import img_galeria_foto1 from '../../assets/galeria/galeria_fotos1.jpg';
import img_galeria_foto2 from '../../assets/galeria/galeria_fotos2.jpg';
import img_galeria_foto3 from '../../assets/galeria/galeria_fotos3.jpg';
import img_galeria_foto5 from '../../assets/galeria/galeria_fotos5.jpg';
import img_galeria_foto6 from '../../assets/galeria/galeria_fotos6.jpeg';
import img_galeria_foto7 from '../../assets/galeria/galeria_fotos7.jpeg';
import { Container, Grid } from '@mui/material';
import img_galeria1 from '../../assets/galeria/galeria1.jpg';
import img_galeria2 from '../../assets/galeria/galeria2.jpg';
import img_galeria3 from '../../assets/galeria/galeria3.jpg';
import img_galeria4 from '../../assets/galeria/galeria4.jpg';
import img_galeria6 from '../../assets/galeria/galeria6.jpg';
import img_galeria7 from '../../assets/galeria/galeria7.jpg';
import img_galeria8 from '../../assets/galeria/galeria8.jpg';
import img_galeria9 from '../../assets/galeria/galeria9.jpg';
import img_galeria10 from '../../assets/galeria/galeria10.jpg';
import img_galeria11 from '../../assets/galeria/galeria11.jpg';
import img_galeria12 from '../../assets/galeria/galeria12.jpg';
import img_galeria13 from '../../assets/galeria/galeria13.jpg';
import img_galeria14 from '../../assets/galeria/galeria14.jpg';
import img_galeria15 from '../../assets/galeria/galeria15.jpg';
import img_galeria16 from '../../assets/galeria/galeria16.jpg';
import img_galeria17 from '../../assets/galeria/galeria17.jpg';
import img_galeria18 from '../../assets/galeria/galeria18.jpg';
import img_galeria19 from '../../assets/galeria/galeria19.jpg';
import img_galeria21 from '../../assets/galeria/galeria21.jpeg';
import img_galeria22 from '../../assets/galeria/galeria22.jpeg';
import img_galeria23 from '../../assets/galeria/galeria23.jpeg';
import img_galeria24 from '../../assets/galeria/galeria24.jpeg';
import img_galeria25 from '../../assets/galeria/galeria25.jpeg';
import img_360 from "../../assets/img/img_360.png"

import img_galeria27 from "../../assets/galeria/img_galeria27.jpg"
import img_galeria28 from "../../assets/galeria/img_galeria28.jpg"
import img_galeria29 from "../../assets/galeria/img_galeria29.jpg"
import img_galeria30 from "../../assets/galeria/img_galeria30.jpg"
import img_galeria31 from "../../assets/galeria/img_galeria31.jpg"
import img_galeria32 from "../../assets/galeria/img_galeria32.jpg"
import img_galeria33 from "../../assets/galeria/img_galeria33.jpg"
import img_galeria35 from "../../assets/galeria/img_galeria35.jpg"
import img_galeria36 from "../../assets/galeria/img_galeria36.jpg"
import img_galeria37 from "../../assets/galeria/img_galeria37.jpg"
import img_galeria38 from "../../assets/galeria/img_galeria38.jpg"
import img_galeria39 from "../../assets/galeria/img_galeria39.jpg"



export default function GaleriaFotos2() {

    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const handlePhotoClick = (event, { photo, index }) => {
        console.log('Clicked photo index:', index);
        // Agrega aquí tu lógica personalizada para el clic en la foto
        openLightbox(index);
    };

    const photos = [

        {
            src: img_galeria_foto1,
            width: 3,
            height: 3
        },
        {
            src: img_galeria_foto2,
            width: 3.5,
            height: 4
        },
        {
            src: img_galeria_foto5,
            width: 3.5,
            height: 4
        },
        {
            src: img_galeria_foto3,
            width: 3.5,
            height: 4
        },
        {
            src: img_galeria_foto6,
            width: 3.5,
            height: 4
        },
        {
            src: img_galeria_foto7,
            width: 4,
            height: 3
        },
        {
            src: img_galeria1,
            width: 4,
            height: 3
        },
        {
            src: img_galeria2,
            width: 4,
            height: 3
        },
        {
            src: img_galeria3,
            width: 4,
            height: 3
        },
        {
            src: img_galeria4,
            width: 3.5,
            height: 4
        },
        {
            src: img_galeria6,
            width: 3.5,
            height: 4
        },
        {
            src: img_galeria7,
            width: 3.5,
            height: 4
        },
        {
            src: img_galeria8,
            width: 3.5,
            height: 4
        },
        {
            src: img_galeria9,
            width: 3.5,
            height: 4
        },
        {
            src: img_galeria10,
            width: 3.5,
            height: 4
        },
        {
            src: img_galeria11,
            width: 3.5,
            height: 4
        },
        {
            src: img_galeria12,
            width: 4,
            height: 3
        },
        {
            src: img_galeria13,
            width: 3.5,
            height: 4
        },
        {
            src: img_galeria14,
            width: 3.5,
            height: 4
        },
        {
            src: img_galeria15,
            width: 4,
            height: 3
        },
        {
            src: img_galeria16,
            width: 3.5,
            height: 4
        },
        {
            src: img_galeria17,
            width: 3.5,
            height: 4
        },
        {
            src: img_galeria18,
            width: 3.5,
            height: 4
        },
        {
            src: img_galeria19,
            width: 3.5,
            height: 4
        },
        {
            src: img_galeria21,
            width: 4,
            height: 3
        },
        {
            src: img_galeria22,
            width: 4,
            height: 3
        },
        {
            src: img_galeria23,
            width: 3.5,
            height: 4
        },
        {
            src: img_galeria24,
            width: 3.5,
            height: 4
        },
        {
            src: img_galeria25,
            width: 3.5,
            height: 4
        },
        {
            src: img_galeria27,
            width: 3.5,
            height: 4
        },
        {
            src: img_galeria28,
            width: 4,
            height: 3
        },
        {
            src: img_galeria29,
            width: 3.5,
            height: 4
        },
        {
            src: img_galeria30,
            width: 3,
            height: 4
        },
        {
            src: img_galeria31,
            width: 4,
            height: 3
        },
        {
            src: img_galeria32,
            width: 3,
            height: 4
        },
        {
            src: img_galeria33,
            width: 3,
            height: 4
        },
        {
            src: img_galeria35,
            width: 4,
            height: 3
        },
        {
            src: img_galeria36,
            width: 3.5,
            height: 4
        },
        {
            src: img_galeria37,
            width: 3.5,
            height: 4
        },
        {
            src: img_galeria38,
            width: 4,
            height: 3
        },
        {
            src: img_galeria39,
            width: 3.5,
            height: 4
        },
        
    ];


    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };




    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Container style={{ marginTop: '20px', textAlign: 'center', maxWidth: 'none', backgroundColor: 'black', paddingBottom: '50px' }} >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button fullWidth /* variant='contained' */ onClick={handleOpen}
                            style={{
                                position: 'relative',
                                padding: 0,
                                /*   marginTop: '45px',
                                  marginBottom: '59px',
                                  border: '2px solid yellow',
                                  width: '100px',
                                  height: '100px',
                                  borderRadius: '8px' */
                                marginBottom: '9px',
                                border: '2px solid #fbcc02',
                                borderRadius: '0px',
                                width: '105px',
                                height: '105px',
                                backgroundColor: 'yell#fbcc02ow'
                            }}  >
                            <img
                                src={img_360}
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    width: '100px',
                                    height: '100px',
                                }}
                            />
                        </Button>
                        <ModalMaterial open={open} onClose={handleClose} >
                            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '95%', height: "90%",/* bgcolor: 'background.paper' */ backgroundColor: 'black', boxShadow: 24, borderRadius: '8px', overflow: 'hidden' }}>
                                <iframe src="https://app.cloudpano.com/tours/Kr7g3bgQo" style={{ width: '100%', height: '100%', border: "1px" }} title="vista 360" />
                            </Box>
                        </ModalMaterial>
                    </Grid>
                </Grid>
                <Gallery photos={photos} onClick={(e, { photo, index }) => openLightbox(index)} />
                <ModalGateway>
                    {viewerIsOpen ? (
                        <Modal onClose={closeLightbox}>
                            <Carousel currentIndex={currentImage} views={photos.map(photo => ({ ...photo, srcset: photo.srcSet, caption: photo.title }))} />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </Container>
        </div >
    )
}
