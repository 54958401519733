import { createTheme } from '@mui/material/styles';


export const main_inicio_pedir_Servicio = createTheme({
    palette: {
        primary: {
            main: '#0AA74E',
            light: '#E4AEF2',
            dark: '#088C42',
            contrastText: '#fff',
        },
        secondary: {
            main: "#F24405",
        },
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    backgroundColor: 'black',
                    paddingBottom:'50px',

                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    padding: "5px",
                    margin: "5px",
                    textAlign: 'center',
                    height: '100%',
                    backgroundColor: '#181819',
                    color: 'white'


                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {

                },
                subtitle2: {
                    fontWeight: 'bold',
                    marginTop: '10px', 
              
                },
            },
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    
                },
                item: {
                    marginBottom: '24px', 
                },
            },
        },
    },
});