import React from 'react';
import { Typography, IconButton } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ThemeProvider } from '@mui/material/styles';
import { themeslider } from '../../components/Slider/theme_slider';
import luxxor_logo from '../../assets/img/Luxxor-Logo.png';
import foto_ford1 from '../../assets/galeria/foto_ford1_editada.JPG'
import foto_ford3 from '../../assets/galeria/foto_ford3_editada.JPG'
import foto_slider_edit from '../../assets/img/foto_slider_editada.jpg';
import foto_slider2_edit from '../../assets/img/foto_Slider2_editada.jpg';
import foto_slider3_edit from '../../assets/img/foto_slider3_editada.jpeg';
import foto_slider4_edit from '../../assets/img/foto_slider4_editada.jpg';
import foto_slider5_edit from '../../assets/img/foto_slider5_editada.jpg';
import foto_slider6_edit from '../../assets/img/foto_slider6_editada.jpg';
import img_prueba from '../../assets/img/img_prueba.jpg'

import foto_slider1 from '../../assets/img/foto_slider_1.jpg'
import foto_slider2 from '../../assets/img/foto_slider_2.jpg'
import foto_slider3 from '../../assets/img/foto_slider_3.jpg'
import foto_slider4 from '../../assets/img/foto_slider_4.jpg'
import foto_slider5 from '../../assets/img/foto_slider_5.jpg'
import foto_slider6 from '../../assets/img/foto_slider_6.jpg'
import foto_slider7 from '../../assets/img/foto_slider_7.jpg'
import foto_slider8 from '../../assets/img/foto_slider_8.jpg'
import foto_slider9 from '../../assets/img/foto_slider_9.jpg'
import foto_slider10 from '../../assets/img/foto_slider_10.jpg'


const images = [
    /* foto_ford1,
    img_prueba,
    foto_slider_edit,
    foto_ford3, 
    foto_slider2_edit,
    foto_slider6_edit,
    foto_slider3_edit,
    foto_slider4_edit,
    foto_slider5_edit */

    foto_slider1,
    foto_slider2,
    foto_slider3,
    foto_slider4,
    foto_slider5,
    foto_slider6,
    foto_slider7,
    foto_slider8,
    foto_slider9,
    foto_slider10

];

/* const textContent = "\"Si las estrellas viajaran, lo harían en Luxxor\""; */

const settings = {
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: false,
    arrows: false,
};

const fontSize = {
    xs: '15px',
    sm: '25px',
    md: '35px',
    lg: '40px',
    xl: '45px',
};

export default function SliderInicio() {
    const sliderRef = React.useRef(null);

    const nextSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    }; //al declarar la funcion NextSlide se verifica si la referencia al componente del slider existe y no sea nula,si existe llama al metodo slicknext y avanza al siguiente slide(cambia de img)

    const prevSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    return (
        <ThemeProvider theme={themeslider}>
            <Slider ref={sliderRef} {...settings}>
                {images.map((image, index) => (
                    <div key={index}>
                        <div style={{ position: 'relative', width: '100%' }}>
                          {/*   <Typography
                                variant="h5"
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    textAlign: 'center',
                                    color: '#fff',
                                    fontSize:fontSize,
                                    fontWeight:'bold',
                                    width: '100%',
                                    zIndex: 1,
                                }}
                            >
                                {textContent}
                            </Typography> */}
                          {/*   <div
                                style={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    background: 'rgba(0, 0, 0, 0.4)', 
                                }}
                            /> */}
                            <img
                                src={image}
                                alt={`Photo ${index + 1}`}
                                style={{
                                    width: '100%',
                                    maxHeight: '40vh',
                                    objectFit: 'cover',
                                    marginBottom: '-4px',
                                }}
                            />  
                        </div>
                    </div>
                ))}
            </Slider>
        </ThemeProvider>
    );
}
