import React, { useEffect, useState } from 'react';
import styles from './alert.module.css';

function Alert() {
    const [showMessage, setShowMessage] = useState(true);
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 4000);
      return () => clearTimeout(timer);
    }, []);
  
    return (
      <div className={`${styles['alert']} ${showMessage ? '' : styles['hide']}`} onClick={e => e.stopPropagation()}>
        <p className={styles['message']}>¿Necesitas Ayuda?</p>
      </div>
    );
  }

export default Alert;