import React, { useState, useContext } from 'react';
import { Grid, Paper, Typography, Container } from '@mui/material';
import { main_inicio_pedir_Servicio } from '../../components/Boton_Pide_Tu_Servicio/theme_boton_pide_tu_servicio';
import { ThemeProvider } from '@mui/material/styles';
import icono_traslado_ejecutivo from '../../assets/img/icono_traslado_ejecutivo_recurrente.png';
import icono_bodas_celebridades_general from '../../assets/img/icono_bodas_traslados_en_general.png';
import icono_eventos_empresariales from '../../assets/img/icono_eventos_empresariales_ejecutivos_entretenimiento.png';
import icono_traslado_aeropuertos_hoteles from '../../assets/img/icono_traslado_aeropuestos_hoteles.png';
import icono_viajes_de_negocios from '../../assets/img/icono_viajes_de_negocios.png';
import icono_desplazamiento_diarios from '../../assets/img/icono_desplazamientos_diarios.png';
import icono_turismo_de_lujos from '../../assets/img/icono_turismo_de_lujos.png';
import { Link } from 'react-router-dom';
import FormularioReservarViaje from '../FormReservarViaje/FormularioReservarViaje';
import { useNavigate } from 'react-router-dom';

import { ReservaViajeContext } from "../../contexts/ReservaViajeContext";

const fontSize = {
    xs: '13px',
    sm: '15px',
    md: '18px',
    lg: '18px',
    xl: '20px',
};



export default function Boton_Pide_Tu_Servicio() {
    const { setServicioDefault } = useContext(ReservaViajeContext);

    const [mostrarFormulario, setMostrarFormulario] = useState(false);
    const [servicioSeleccionado, setServicioSeleccionado] = useState(null);


    const navigate = useNavigate();

    const handleTrasladoEjecutivoClick = () => {
        setServicioDefault('Traslado Ejecutivo Recurrente');
        navigate('/traslado_ejecutivo_recurrente');
    };

    const handleBodasCelebridadesClick = () => {
        setServicioDefault('Bodas y Celebraciones en General');
        navigate('/bodas_celebridades_en_general');
    };

    const handleEventosEmpresarialesClick = () => {
        setServicioDefault('Eventos Empresariales, Deportivos y de Entretenimiento');
        navigate('/eventos_empresariales_deportivos_entretenimiento');
    };

    const handleEventosTrasladosAeropuertosClick = () => {
        setServicioDefault('Traslados a Aeropuerto y Hoteles');
        navigate('/traslados_aeropuertos_y_hoteles');
    };

    const handleViajesDeNegociosClick = () => {
        setServicioDefault('Viajes de Negocios');
        navigate('/viajes_de_negocios');
    };

    const handleDesplazamientosDiariosClick = () => {
        setServicioDefault('Desplazamientos Diarios');
        navigate('/desplazamientos_diarios');
    };

    const handleTurismoDeLujosClick = () => {
        setServicioDefault('Turismo de Lujo');
        navigate('/turismo_de_lujos');
    };


    const handleClick = (servicio) => {
        setMostrarFormulario(true);
        setServicioSeleccionado(servicio);
        console.log(servicio)
    };



    return (
        <ThemeProvider theme={main_inicio_pedir_Servicio}>
            <Container maxWidth={false} style={{ marginTop: '20px' }} >
                {mostrarFormulario ? (
                    <FormularioReservarViaje servicioDefault={servicioSeleccionado} />
                ) : (
                    <Grid container spacing={2} justifyContent="center">

                        <Grid container item xs={12} justifyContent="center">
                            <Grid item xs={4} md={4}>
                                <a href="https://order.geturvan.com/luxxor" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <Paper elevation={3} /* onClick={handleTrasladoEjecutivoClick}  */>
                                        <img src={icono_traslado_ejecutivo} style={{ width: '100%', maxWidth: '75px', height: 'auto' }} />
                                        <Typography variant="subtitle2" sx={{ fontSize: fontSize }}>
                                            Traslado Ejecutivo Recurrente
                                        </Typography>
                                    </Paper>
                                </a>
                            </Grid>

                            <Grid item xs={4} md={4}>
                                <a href="https://order.geturvan.com/luxxor" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <Paper elevation={3} /* onClick={handleBodasCelebridadesClick} */>
                                        <img src={icono_bodas_celebridades_general} style={{ width: '100%', maxWidth: '75px', height: 'auto' }} />
                                        <Typography variant="subtitle2" sx={{ fontSize: fontSize }}>
                                            Bodas y Celebraciones en General
                                        </Typography>
                                    </Paper>
                                </a>
                            </Grid>

                            <Grid item xs={4} md={4}>
                                <a href="https://order.geturvan.com/luxxor" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <Paper elevation={3} /* onClick={handleEventosEmpresarialesClick} */>
                                        <img src={icono_eventos_empresariales} style={{ width: '100%', maxWidth: '75px', height: 'auto' }} />
                                        <Typography variant="subtitle2" sx={{ fontSize: fontSize }}>
                                            Eventos Empresariales, Deportivos y de Entretenimiento
                                        </Typography>
                                    </Paper>
                                </a>
                            </Grid>
                        </Grid>


                        <Grid container item xs={12} justifyContent="center">
                            <Grid item xs={4} md={4}>
                                <a href="https://order.geturvan.com/luxxor" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <Paper elevation={3} /* onClick={handleEventosTrasladosAeropuertosClick} */>
                                        <img src={icono_traslado_aeropuertos_hoteles} style={{ width: '100%', maxWidth: '75px', height: 'auto' }} />
                                        <Typography variant="subtitle2" sx={{ fontSize: fontSize }}>
                                            Traslados a Aeropuertos y Hoteles
                                        </Typography>
                                    </Paper>
                                </a>
                            </Grid>

                            <Grid item xs={4} md={4}>
                                <a href="https://order.geturvan.com/luxxor" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <Paper elevation={3} /* onClick={handleViajesDeNegociosClick} */>
                                        <img src={icono_viajes_de_negocios} style={{ width: '100%', maxWidth: '75px', height: 'auto' }} />
                                        <Typography variant="subtitle2" sx={{ fontSize: fontSize }}>
                                            Viajes de Negocios
                                        </Typography>
                                    </Paper>
                                </a>
                            </Grid>

                            <Grid item xs={4} md={4}>
                                <a href="https://order.geturvan.com/luxxor" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <Paper elevation={3} /* onClick={handleDesplazamientosDiariosClick} */>
                                        <img src={icono_desplazamiento_diarios} style={{ width: '100%', maxWidth: '75px', height: 'auto' }} />
                                        <Typography variant="subtitle2" sx={{ fontSize: fontSize }}>
                                            Desplazamientos Diarios
                                        </Typography>
                                    </Paper>
                                </a>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} justifyContent="center">
                            <Grid item xs={4} md={4}>
                                <a href="https://order.geturvan.com/luxxor" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <Paper elevation={3} /* onClick={handleTurismoDeLujosClick} */>
                                        <img src={icono_turismo_de_lujos} style={{ width: '100%', maxWidth: '75px', height: 'auto' }} />
                                        <Typography variant="subtitle2" sx={{ fontSize: fontSize }}>
                                            Turismo de Lujos
                                        </Typography>
                                    </Paper>
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </ThemeProvider >
    );
};
