import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker, DirectionsRenderer, useJsApiLoader } from '@react-google-maps/api';
import { capitalize } from '../../Utils/utils';

const containerStyle = {
    width: '100%',
    height: '350px',
};

const libraries = ['places'];

const GOOGLE_MAP_DISTANCE_MATRIX_KEY = process.env.REACT_APP_API_DISTANCE_MATRIX_KEY;

const GoogleMapsTracking = ({ origen, destino }) => {
    const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
    const [zoomLevel, setZoomLevel] = useState(12);
    const [distance, setDistance] = useState("");
    const [directions, setDirections] = useState(null);
    const [markersInfoAlumno, setMarkersInfoAlumno] = useState(null);
    const [markersColegioInfoAlumno, setMarkersColegioInfoAlumno] = useState(null);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_MAP_DISTANCE_MATRIX_KEY ,
        libraries,
    });

    useEffect(() => {
        if (isLoaded) {
            const geocoder = new window.google.maps.Geocoder();
            const directionsService = new window.google.maps.DirectionsService();
            const distanceMatrixService = new window.google.maps.DistanceMatrixService();

            // Function to set markers and center
            const setMarkersAndCenter = (origPosition, destPosition) => {
                setMapCenter(origPosition);

                setMarkersInfoAlumno([{
                    position: origPosition,
                    title: origen,
                    nombre: capitalize("Nombre de cliente"),
                }]);

                setMarkersColegioInfoAlumno([{
                    position: destPosition,
                    title: destino,
                    nombre: "Destino",
                }]);
            };

            // Function to fetch directions
            const fetchDirections = (origPos, destPos) => {
                directionsService.route({
                    origin: origPos,
                    destination: destPos,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                }, (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setDirections(result);
                    } else {
                        console.error(`error fetching directions ${result}`);
                    }
                });
            };

            // Function to calculate distance
            const calculateDistance = (orig, dest) => {
                distanceMatrixService.getDistanceMatrix({
                    origins: [orig],
                    destinations: [dest],
                    travelMode: 'DRIVING',
                }, (response, status) => {
                    if (status === 'OK') {
                        const results = response.rows[0].elements[0];
                        setDistance(`Distancia: ${results.distance.text}, Duración: ${results.duration.text}`);
                    } else {
                        console.error('Error with distance matrix:', status);
                    }
                });
            };

            // Geocode origin address
            geocoder.geocode({ address: origen }, (resultsOrig, statusOrig) => {
                if (statusOrig === 'OK') {
                    const origPosition = resultsOrig[0].geometry.location;

                    // Geocode destination address
                    geocoder.geocode({ address: destino }, (resultsDest, statusDest) => {
                        if (statusDest === 'OK') {
                            const destPosition = resultsDest[0].geometry.location;

                            // Set markers and center
                            setMarkersAndCenter(origPosition, destPosition);

                            // Fetch and set directions
                            fetchDirections(origPosition, destPosition);

                            // Calculate distance
                            calculateDistance(origPosition, destPosition);
                        }
                    });
                }
            });
        }
    }, [origen, destino, isLoaded]);

    return isLoaded ? (
        <div>
            {directions && (
                <GoogleMap mapContainerStyle={containerStyle} center={mapCenter} zoom={zoomLevel}>
                    <DirectionsRenderer directions={directions} />
                    {markersInfoAlumno && markersInfoAlumno.map((marker, index) => (
                        <Marker key={index} position={marker.position} title={marker.nombre} />
                    ))}
                    {markersColegioInfoAlumno && markersColegioInfoAlumno.map((markerColegio, index) => (
                        <Marker key={index} position={markerColegio.position} title={markerColegio.nombre} />
                    ))}
                </GoogleMap>
            )}
            {distance && <p>{distance}</p>}
            <p>Origen: {origen}</p>
            <p>Destino: {destino}</p>
        </div>
    ) : <div>Loading...</div>;
};

export default GoogleMapsTracking;
