import React from 'react';
import styles from './bubble_Bot.module.css';

function BubbleBot({ onClick }) {

  return (
    <div id='bubble' onClick={onClick}>
      <img src="/static/media/icons/bubble-chatbot.webp" alt="Chatbot" className={styles['bubble-chatbot']} />
    </div>
  );
}

export default BubbleBot;