import React from 'react'
import SliderInicio from '../Slider/SliderInicio';
import ContainerReservarViaje from '../ContainerReservar_Viaje/ContainerReservarViaje';
import VistaGaleriaFotos from '../GaleriaFotos/GaleriaFotos2';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';

export default function GaleriaFotos() {
  return (
    <div>
      <Navbar />
      <SliderInicio />
      <ContainerReservarViaje />
      <VistaGaleriaFotos />
      <Footer />
    </div>
  )
}
