import React from 'react'
import SliderInicio from '../Slider/SliderInicio'
import ContainerReservarViaje from '../ContainerReservar_Viaje/ContainerReservarViaje'
import FormularioReservarViaje from '../FormReservarViaje/FormularioReservarViaje'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'

export default function Vista_Bodas_Celebridades_En_General() {
  return (
    <div>
      <Navbar />
      <SliderInicio />
      <ContainerReservarViaje />
      <FormularioReservarViaje />
      <Footer />
    </div>
  )
}
