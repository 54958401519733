import React from "react";
import { Typography, Grid, Paper } from '@mui/material';
import { Container } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { main_inicio } from '../../components/ContainerMain/theme_container_main';
import icono_visita_instagram from '../../assets/img/icono_visita_instagram.png';
import icono_galeria_luxxor from '../../assets/img/icono_galeria_luxxor.png';
import icono_servicio from '../../assets/img/icono_pedir_servicio.png';
import icono_asistencia_personalizada from '../../assets/img/icono_asistencia_personalizada.png';
import { Link } from 'react-router-dom';

const fontSize = {
    xs: '14px',
    sm: '15px',
    md: '18px',
    lg: '18px',
    xl: '20px',
};

export default function BotonLuxxor() {

    const link_instagram = 'https://www.instagram.com/luxxor.cl/';
    const link_whatsapp = 'https://api.whatsapp.com/message/3BQ4NBSTHMQMM1?autoload=1&app_absent=0';

    const handleInstagramClick = () => {
        window.open(link_instagram, '_blank');
    };

    const handleWhatsappClick = () => {
        window.open(link_whatsapp, '_blank');
    };

    return (
        <ThemeProvider theme={main_inicio}>
            <Container maxWidth={false} style={{ marginTop: '20px' }}>
                <Grid container spacing={2} justify="center">
                    <Grid container item xs={12} justifyContent="center" style={{ marginBottom: '60px' }}>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Paper elevation={3}>
                                <Link to='/galeria_fotos'>
                                    <img src={icono_galeria_luxxor} alt="Alt Galeria de fotos" style={{ width: '100%', maxWidth: '75px', height: 'auto' }} />
                                </Link>
                                <Typography variant="subtitle2" sx={{ fontSize: fontSize }}>
                                    Galer&iacute;a Luxxor
                                </Typography>
                            </Paper>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Paper elevation={3}>
                                <Link onClick={handleInstagramClick}>
                                    <img src={icono_visita_instagram} alt="Categoría Instagram" style={{ width: '50%', maxWidth: '75px', height: 'auto' }} />
                                </Link>
                                <Typography variant="subtitle2" sx={{ fontSize: fontSize }}>
                                    Visita nuestro instagram
                                </Typography>
                            </Paper>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Paper elevation={3}>
                                <Link onClick={handleWhatsappClick}>
                                    <img src={icono_asistencia_personalizada} alt="Categoría Asistencia Personalizada" style={{ width: '50%', maxWidth: '75px', height: 'auto' }} />
                                </Link>
                                <Typography variant="subtitle2" sx={{ fontSize: fontSize }}>
                                    Asistencia Personalizada
                                </Typography>
                            </Paper>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Paper elevation={3}>
                                <Link to="/servicios">
                                    <img src={icono_servicio} alt="Categoría Servicios Luxxor" style={{ width: '50%', maxWidth: '75px', height: 'auto' }} />
                                </Link>
                                <Typography variant="subtitle2" sx={{ fontSize: fontSize }}>
                                    Servicios Luxxor
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider >

    )
}
